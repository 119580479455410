import { DateConverterService } from "./../dateConverter/date-converter.service";
import { Pipe, PipeTransform } from "@angular/core";
import { NepaliDateService } from "@app/shared/services/nepali-date/nepalidate.service";

// declare var require: any;
// var adbs = require("ad-bs-converter");
@Pipe({
  name: "dayNumberPipe"
})
export class DayNumberPipe implements PipeTransform {
  constructor(private dateConverterService: DateConverterService, private adbs : NepaliDateService) {}
  transform(value: string, dateType: string,twoDigit?:string) {
    // console.log(twoDigit)
    if (dateType == "N") {
      //   value = this.datePipe.transform(value, "yyyy/MM/dd");
      value = this.dateConverterService.dateStringFormater(value);
      // console.log(value);
      let dateObject = this.adbs.ad2bs(value);
      if(twoDigit){
        // console.log("retuning",dateObject.en.day);
        return dateObject.en.day.toString().length == 1 ? `0${dateObject.en.day}`:dateObject.en.day;
      }
      else{
        return dateObject.en.day;
      }
    } else {
      value = this.dateConverterService.dateStringFormater(value);
      let dateOjectInBs = this.adbs.ad2bs(value);
      let dateObjectInAd = this.adbs.bs2ad(
        `${dateOjectInBs.en.year}/${dateOjectInBs.en.month}/${dateOjectInBs.en.day}`
      );
      // console.log( dateObjectInAd.day)
      // console.log(typeof(dateObjectInAd.day));
      // console.log("in string",dateObjectInAd.day.toString())
      if(twoDigit){
       return  dateObjectInAd.day.toString().length == 1 ? `0${dateObjectInAd.day}`:dateObjectInAd.day;
      }

     else{
      return dateObjectInAd.day;
     }
    }
  }
}
//if correction to library is done, use above code

// import { AdBsDateConvertService } from "@app/shared/components/custom-nepalidatepicker/services/ad-bs-date-convert.service";
// import { DateConverterService } from "./../dateConverter/date-converter.service";
// import { Pipe, PipeTransform } from "@angular/core";


// declare var require: any;
// var adbs = require("ad-bs-converter");

// @Pipe({
//   name: "dayNumberPipe",
// })
// export class DayNumberPipe implements PipeTransform {
//   constructor(
//     private dateConverterService: DateConverterService,
//     private adbsdateconverterservice: AdBsDateConvertService
//   ) {}
//   transform(value: string, dateType: string, twoDigit?: string) {
//     value = this.dateConverterService.dateStringFormater(value);

//     let dateObject;
//     if (dateType === "N") {
//       dateObject = adbs.ad2bs(value);
//       const lastDayOfMonth = this.adbsdateconverterservice.getLastdateOfNepaliMonth(dateObject.en.month);
//       if (
//         dateObject.en.month === 12 ||
//         dateObject.en.year > 2081 ||
//         (dateObject.en.year === 2081 &&
//           dateObject.en.month === 11 &&
//           dateObject.en.day >= 30)
//       ) {
//         return dateObject.en.day < lastDayOfMonth ? dateObject.en.day + 1 : 1;
//       }
//     } else {
//       let dateObjectInBs = adbs.ad2bs(value);
//       dateObject = adbs.bs2ad(
//         `${dateObjectInBs.en.year}/${dateObjectInBs.en.month}/${dateObjectInBs.en.day}`
//       );

//       const shouldIncrementDay =
//         dateObject.en.year < 2025 ||
//         (dateObject.en.year === 2025 && dateObject.en.month < 3) ||
//         (dateObject.en.year === 2025 &&
//           dateObject.en.month === 3 &&
//           dateObject.en.day < 13);
//       if (shouldIncrementDay) {
//         dateObject.en.day += 1;
//       }
//     }
//     let day = dateObject.en.day;
//     const daysInMonth = this.adbsdateconverterservice.getLastdateOfNepaliMonth(dateObject.en.month);

//     if (day > daysInMonth) {
//       day = 1; // Reset to the first day of the next month
//       dateObject.en.month += 1; // Move to the next month
//       if (dateObject.en.month > 12) {
//         dateObject.en.month = 1;
//         dateObject.en.year += 1;
//       }
//     }

//     if (twoDigit) {
//       return day.toString().length === 1 ? `0${day}` : day;
//     } else {
//       return day;
//     }
//   }



// }
