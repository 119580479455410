import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { NepaliDateService } from "@app/shared/services/nepali-date/nepalidate.service";

// declare var require: any;
// var adbs = require("ad-bs-converter");
@Pipe({
  name: "dayPipe",
})
export class DayPipe implements PipeTransform {
  days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  constructor(private datePipe: DatePipe, private adbs: NepaliDateService) {}
  transform(value: string,type?:string,shortcut?:boolean) {
    value = this.datePipe.transform(value, "yyyy/MM/dd");

    // console.log("shortcut",shortcut)
    if(shortcut && shortcut == true){
      return this.days[this.adbs.ad2bs(value).en.dayOfWeek].substr(0,3);
    }else{
      return this.days[this.adbs.ad2bs(value).en.dayOfWeek];
    }

  }
}


// import { DatePipe } from "@angular/common";
// import { Pipe, PipeTransform } from "@angular/core";

// declare var require: any;
// var adbs = require("ad-bs-converter");

// @Pipe({
//   name: "dayPipe",
// })
// export class DayPipe implements PipeTransform {
//   days = [
//     "Sunday",
//     "Monday",
//     "Tuesday",
//     "Wednesday",
//     "Thursday",
//     "Friday",
//     "Saturday",
//   ];

//   constructor(private datePipe: DatePipe) {}

//   transform(value: any, type?: string, shortcut?: boolean) {
//     // Ensure value is a string and format it
//     if (typeof value !== 'string') {
//       value = this.datePipe.transform(value, "yyyy/MM/dd");
//     }

//     // Log the formatted value for debugging
//     console.log('Formatted Date Value:', value);

//     // Convert the AD date to BS date
//     let bsDate;
//     try {
//       bsDate = adbs.ad2bs(value);
//     } catch (error) {
//       console.error('Error converting AD to BS:', error);
//       return 'Invalid Date'; // Return a fallback value or handle the error as needed
//     }

//     let nextDay = bsDate.en.day + 1;
//     const daysInMonth = this.getLastdateOfNepaliMonth(bsDate.en.month);

//     // Check if the next day exceeds the number of days in the month
//     if (nextDay > daysInMonth) {
//       nextDay = 1; // Reset to the first day of the next month
//       bsDate.en.month += 1; // Move to the next month

//       // If the month exceeds 12, reset to January and increment the year
//       if (bsDate.en.month > 12) {
//         bsDate.en.month = 1; // Reset to January
//         bsDate.en.year += 1; // Increment the year
//       }
//     }

//     // Prepare the date string for bs2ad
//     const bsDateString = `${bsDate.en.year}/${this.getTwoDigitString(bsDate.en.month)}/${this.getTwoDigitString(nextDay)}`;

//     // Get the day of the week for the adjusted BS date
//     let dayOfWeek;

//     try {
//       dayOfWeek = adbs.bs2ad(bsDate.en.year, bsDate.en.month, nextDay).en.dayOfWeek;
//     } catch (error) {
//       console.error('Error converting BS to AD:', error);
//       return 'Invalid Date'; // Return a fallback value or handle the error as needed
//     }

//     // Return the appropriate day name based on the shortcut flag
//     if (shortcut) {
//       return this.days[dayOfWeek].substr(0, 3); // Return abbreviated day name
//     } else {
//       return this.days[dayOfWeek]; // Return full day name
//     }
//   }

//   // Function to get the last date of a given Nepali month
//   private getLastdateOfNepaliMonth(month: number): number {
//     month = parseInt(month.toString());
//     let value;
//     switch (month) {
//       case 1:
//         value = 31;
//         break;
//       case 2:
//         value = 31;
//         break;
//       case 3:
//         value = 31;
//         break;
//       case 4:
//         value = 32;
//         break;
//       case 5:
//         value = 31;
//         break;
//       case 6:
//         value = 31;
//         break;
//       case 7:
//         value = 30;
//         break;
//       case 8:
//         value = 29;
//         break;
//       case 9:
//         value = 30;
//         break;
//       case 10:
//         value = 29;
//         break;
//       case 11:
//         value = 29;
//         break;
//       case 12:
//         value = 31;
//         break;
//       default:
//         value = 30;
//         break;
//     }
//     return value;
//   }

//   // Helper function to format numbers as two digits
//   private getTwoDigitString(value: number): string {
//     return value < 10 ? `0${value}` : value.toString();
//   }
// }
