import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { NepaliDateService } from "@app/shared/services/nepali-date/nepalidate.service";

// declare var require: any;
// var adbs = require("ad-bs-converter");
@Pipe({
  name: "dateConversionPipe",
})
export class DateConversionPipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private adbs : NepaliDateService) {}
  getLastdateOfNepaliMonth(month) {
    month = parseInt(month);
    // month = parseInt(month)
    let value;
    switch (month) {
      case 1:
        value = 31;
        break;
      case 2:
        value = 31;
        break;
      case 3:
        value = 31;
        break;

      case 4:
        value = 32;
        break;
      case 5:
        value = 31;
        break;
      case 6:
        value = 31;
        break;
      case 7:
        value = 30;
        break;
      case 8:
        value = 29;
        break;
      case 9:
        value = 30;
        break;
      case 10:
        value = 29;
        break;
      case 11:
        value = 29;
        break;
      case 12:
        value = 31;
        break;
      default:
        value = 30;
        break;
    }
    return value;
  }

  getTwoDigitString(value: number): string {
    //returns two digit for single digit date
    if (value.toString().length == 1) {
      return "0" + value.toString();
    } else {
      return value.toString();
    }
  }


  transform(value: string, dateType: string) {
    if (dateType == "N") {
      value = this.datePipe.transform(value, "yyyy/MM/dd");
      // console.log(value);
      let dateObject = this.adbs.ad2bs(value);
      var nextDay = dateObject.en.day + 1;
        var daysInMonth = this.getLastdateOfNepaliMonth(dateObject.en.month);

        if (nextDay > daysInMonth) {
          nextDay = 1; // Reset to the first day of the next month
          dateObject.en.month += 1; // Move to the next month

          if (dateObject.en.month > 12) {
            dateObject.en.month = 1; // Reset to January
            dateObject.en.year += 1; // Increment the year
          }
        }
        return (

          this.getTwoDigitString(dateObject.en.month) +
          "/" +
          this.getTwoDigitString(nextDay) +  "/" +  dateObject.en.year

        );
      // return `${dateObject.en.year}-${dateObject.en.month}-${dateObject.en.day}`;
    } else {
      return value;
    }
  }
}
