import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NepaliDateService {
  private defaults = {
    lang: 'ne', // possible values: ne for Nepali text, en for English text
    monthFormat: 'full', // possible values: full for full name, short for short name
    daysFormat: 'min', // possible values: full for full name, short for short name and min for minified name
  };

  private ne = {
    monthsName: ['बैशाख', 'जेष्ठ', 'आषाढ', 'श्रावण', 'भाद्र', 'आश्विन', 'कार्तिक', 'मंसिर', 'पौष', 'माघ', 'फाल्गुन', 'चैत्र'],
    monthsShortName: ['बै', 'जे', 'आषा', 'श्रा', 'भा', 'आश', 'का', 'मं', 'पौ', 'मा', 'फा', 'चै'],
    daysName: ['आइतबार', 'सोमबार', 'मंगलबार', 'बुधबार', 'बिहिबार', 'शुक्रबार', 'शनिबार'], // Full names of days
    daysShortName: ['आइ', 'सो', 'मं', 'बु', 'बि', 'शु', 'श'], // Short names of days
  };

  private en = {
    monthsName: ['Baisakh', 'Jestha', 'Ashadh', 'Shrawan', 'Bhadra', 'Ashwin', 'Kartik', 'Mangsir', 'Paush', 'Mangh', 'Falgun', 'Chaitra'],
    monthsShortName: ['Bai', 'Je', 'As', 'Shra', 'Bha', 'Ash', 'Kar', 'Mang', 'Pau', 'Ma', 'Chai'],
    daysName: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], // Full names of days
    daysShortName: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'], // Short names of days
  };

  engDaysName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  engDaysShortName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  engMonthsName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  engMonthsShortName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  daysInYear = 365;
  minMonth = 1;
  minDays = 1;
  maxMonth = 12;
  maxDays = 32;

  private base_ad = { year: 2017, month: 2, day: 11, dayOfWeek: 6 }; // Base AD date
  private base_bs = { year: 2073, month: 10, day: 29, dayOfWeek: 6 }; // Base BS date

  private calendarData = {
    '1978': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '1979': [ 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365 ],
    '1980': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366 ],
    '1981': [ 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365 ],
    '1982': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '1983': [ 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365 ],
    '1984': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366 ],
    '1985': [ 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365 ],
    '1986': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '1987': [ 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365 ],
    '1988': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366 ],
    '1989': [ 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '1990': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '1991': [ 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365 ],
    '1992': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366 ],
    '1993': [ 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '1994': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '1995': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30, 365 ],
    '1996': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366 ],
    '1997': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '1998': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '1999': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366 ],
    '2000': [ 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 365 ],
    '2001': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2002': [ 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365 ],
    '2003': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366 ],
    '2004': [ 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 365 ],
    '2005': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2006': [ 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365 ],
    '2007': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366 ],
    '2008': [ 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31, 365 ],
    '2009': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2010': [ 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365 ],
    '2011': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366 ],
    '2012': [ 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365 ],
    '2013': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2014': [ 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365 ],
    '2015': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366 ],
    '2016': [ 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365 ],
    '2017': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2018': [ 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365 ],
    '2019': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366 ],
    '2020': [ 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2021': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2022': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30, 365 ],
    '2023': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366 ],
    '2024': [ 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2025': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2026': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366 ],
    '2027': [ 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 365 ],
    '2028': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2029': [ 31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30, 365 ],
    '2030': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366 ],
    '2031': [ 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 365 ],
    '2032': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2033': [ 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365 ],
    '2034': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366 ],
    '2035': [ 30, 32, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31, 365 ],
    '2036': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2037': [ 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365 ],
    '2038': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366 ],
    '2039': [ 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365 ],
    '2040': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2041': [ 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365 ],
    '2042': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366 ],
    '2043': [ 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365 ],
    '2044': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2045': [ 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365 ],
    '2046': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366 ],
    '2047': [ 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2048': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2049': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30, 365 ],
    '2050': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366 ],
    '2051': [ 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2052': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2053': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30, 365 ],
    '2054': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366 ],
    '2055': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2056': [ 31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30, 365 ],
    '2057': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366 ],
    '2058': [ 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 365 ],
    '2059': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2060': [ 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365 ],
    '2061': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366 ],
    '2062': [ 30, 32, 31, 32, 31, 31, 29, 30, 29, 30, 29, 31, 365 ],
    '2063': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2064': [ 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365 ],
    '2065': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366 ],
    '2066': [ 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31, 365 ],
    '2067': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2068': [ 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365 ],
    '2069': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366 ],
    '2070': [ 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365 ],
    '2071': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2072': [ 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365 ],
    '2073': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366 ],
    '2074': [ 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2075': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2076': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30, 365 ],
    '2077': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366 ],
    '2078': [ 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2079': [ 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365 ],
    '2080': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30, 365 ],
    '2081': [ 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366 ],
    '2082': [ 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30, 365 ],
    '2083': [ 31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30, 365 ],
    '2084': [ 31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30, 365 ],
    '2085': [ 31, 32, 31, 32, 30, 31, 30, 30, 29, 30, 30, 30, 366 ],
    '2086': [ 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30, 365 ],
    '2087': [ 31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30, 366 ],
    '2088': [ 30, 31, 32, 32, 30, 31, 30, 30, 29, 30, 30, 30, 365 ],
    '2089': [ 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30, 365 ],
    '2090': [ 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30, 365 ],
    '2091': [ 31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30, 366 ],
    '2092': [ 31, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30, 366 ]
  };

  constructor() { }

  // Step 2: Implement the first function
  countDaysInYear(year: number): number {
    if (typeof this.calendarData[year] === 'undefined') {
      return 365; // Default to 365 days if year data is not found
    }
    return this.calendarData[year][12]; // Return the total days in the year
  }

    isLeapYear(year: number): boolean {
    return (this.countDaysInYear(year) === 366);
  }

  // Function to count days in the BS calendar
// countBSDays(date: string): number {
//   let dayCount = 0;
//   const dateArr = date.split('/').map(str => Number(str));
//   const dateObj = { year: dateArr[0], month: dateArr[1], day: dateArr[2] };

//   let inc = false;
//   if (dateArr[0] > this.base_bs.year) {
//     inc = true;
//   } else if (dateArr[0] === this.base_bs.year && dateArr[1] > this.base_bs.month) {
//     inc = true;
//   } else if (dateArr[0] === this.base_bs.year && dateArr[1] === this.base_bs.month && dateArr[2] > this.base_bs.day) {
//     inc = true;
//   }

//   const start = inc ? this.base_bs : dateObj;
//   const end = inc ? dateObj : this.base_bs;
//   const factor = inc ? 1 : -1;

//   for (let i = start.year; i <= end.year; i++) {
//     dayCount += this.calendarData[i][12];
//   }

//   for (let i = 0; i < start.month - 1; i++) {
//     dayCount -= this.calendarData[start.year][i];
//   }

//   for (let i = end.month - 1; i < 12; i++) {
//     dayCount -= this.calendarData[end.year][i];
//   }

//   dayCount -= start.day;
//   dayCount += end.day;
//   return dayCount * factor;
// }

countBSDays(date: string): number {
  let dayCount = 0;
  const dateArr = date.split('/').map(str => Number(str));
  const dateObj = { year: dateArr[0], month: dateArr[1], day: dateArr[2] };

  let inc = false;
  if (dateArr[0] > this.base_bs.year) {
    inc = true;
  } else if (dateArr[0] === this.base_bs.year && dateArr[1] > this.base_bs.month) {
    inc = true;
  } else if (dateArr[0] === this.base_bs.year && dateArr[1] === this.base_bs.month && dateArr[2] > this.base_bs.day) {
    inc = true;
  }

  const start = inc ? this.base_bs : dateObj;
  const end = inc ? dateObj : this.base_bs;
  const factor = inc ? 1 : -1;

  for (let i = start.year; i <= end.year; i++) {
    if (!this.calendarData[i]) {
      // console.error(`No data for year: ${i}`);
      continue; // or handle the error as needed
    }
    dayCount += this.calendarData[i][12]; // Ensure this index is valid
  }

  for (let i = 0; i < start.month - 1; i++) {
    if (this.calendarData[start.year]) {
      dayCount -= this.calendarData[start.year][i];
    }
  }

  for (let i = end.month - 1; i < 12; i++) {
    if (this.calendarData[end.year]) {
      dayCount -= this.calendarData[end.year][i];
    }
  }

  dayCount -= start.day;
  dayCount += end.day;
  return dayCount * factor;
}

// Function to count days in the AD calendar
countADDays(date: string): { diffDays: number, dateInAd: Date } {
  const dateArr = date.split('/').map(str => Number(str));
  const dateObj = { year: dateArr[0], month: dateArr[1] - 1, day: dateArr[2] };

  const date1 = new Date(this.base_ad.year, this.base_ad.month - 1, this.base_ad.day);
  const date2 = new Date(dateObj.year, dateObj.month, dateObj.day);
  const timeDiff = date2.getTime() - date1.getTime();
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return { diffDays, dateInAd: date2 };
}

// Function to offset BS days
offsetBSDays(dayData: any): any {
  let dayCount = dayData.diffDays;
  let dateInAd = dayData.dateInAd;
  let bs_date = { ...this.base_bs }; // Create a copy of the base BS date

  if (dayCount >= 0) {
    bs_date.day += dayCount;
    while (bs_date.day > this.calendarData[bs_date.year][bs_date.month - 1]) {
      bs_date.day -= this.calendarData[bs_date.year][bs_date.month - 1];
      bs_date.month++;
      if (bs_date.month > 12) {
        bs_date.year++;
        bs_date.month = 1;
      }
    }
  } else {
    dayCount = Math.abs(dayCount);
    while (dayCount >= 0) {
      if (dayCount < this.calendarData[bs_date.year][bs_date.month - 1]) {
        dayCount = this.calendarData[bs_date.year][bs_date.month - 1] - dayCount;
        break;
      }
      dayCount -= this.calendarData[bs_date.year][bs_date.month - 1];
      bs_date.month--;
      if (bs_date.month === 0) {
        bs_date.year--;
        bs_date.month = 12;
      }
    }
    bs_date.day = dayCount;
  }

  let month = dateInAd.getMonth();
  let dayOfWeek = dateInAd.getDay();
  let npDayOfWeek = this.getNepaliDayOfWeek(dayOfWeek);
  let enDayOfWeek = this.getEnglishDayOfWeek(dayOfWeek);

  let totalDays = this.calendarData[bs_date.year][bs_date.month - 1];
  let dateObj = {
    ne: {
      year: this.getNepaliNumber(bs_date.year),
      month: this.getNepaliNumber(bs_date.month),
      day: this.getNepaliNumber(bs_date.day),
      strMonth: this.ne.monthsName[bs_date.month - 1],
      strShortMonth: this.ne.monthsShortName[bs_date.month - 1],
      dayOfWeek: this.getNepaliNumber(dayOfWeek),
      strDayOfWeek: npDayOfWeek.full,
      strShortDayOfWeek: npDayOfWeek.short,
      strMinDayOfWeek: npDayOfWeek.min,
      totalDaysInMonth: this.getNepaliNumber(totalDays)
    },
    en: {
      year: bs_date.year,
      month: bs_date.month,
      day: bs_date.day,
      strMonth: this.en.monthsName[bs_date.month - 1],
      strShortMonth: this.en.monthsShortName[bs_date.month - 1],
      dayOfWeek: dayOfWeek,
      strDayOfWeek: enDayOfWeek.full,
      strShortDayOfWeek: enDayOfWeek.short,
      strMinDayOfWeek: enDayOfWeek.min,
      totalDaysInMonth: totalDays
    }
  };

  return dateObj;
}

// Helper function to get Nepali day of the week
private getNepaliDayOfWeek(dayOfWeek: number): any {
  const days = ['आइतबार', 'सोमबार', 'मंगलबार', 'बुधबार', 'बिहिबार', 'शुक्रबार', 'शनिबार'];
  return {
    full: days[dayOfWeek],
    short: days[dayOfWeek].substring(0, 3),
    min: days[dayOfWeek].substring(0, 1)
  };
}

// Helper function to get English day of the week
private getEnglishDayOfWeek(dayOfWeek: number): any {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return {
    full: days[dayOfWeek],
    short: days[dayOfWeek].substring(0, 3),
    min: days[dayOfWeek].substring(0, 1)
  };
}

// Helper function to convert numbers to Nepali format
private getNepaliNumber(num: number): string {
  const nums = ['०', '१', '२', '३', '४', '५', '६', '७', '८', '९'];
  return num.toString().split('').map(digit => nums[parseInt(digit)]).join('');
}

// Function to offset AD days
offsetADDays(dayCount: number): any {
  // Create a new Date object for the base AD date
  const date = new Date(this.base_ad.year, this.base_ad.month - 1, this.base_ad.day);

  // Add the day count to the date
  date.setDate(date.getDate() + dayCount);

  // Extract the new date components
  const month = date.getMonth();
  const dayOfWeek = date.getDay();

  // Prepare the date object
  const dateObj = {
    year: date.getFullYear(),
    month: month + 1, // Month is 0-indexed in JavaScript
    strMonth: this.engMonthsName[month],
    strShortMonth: this.engMonthsShortName[month],
    day: date.getDate(),
    dayOfWeek: dayOfWeek,
    strDayOfWeek: this.engDaysName[dayOfWeek],
    strShortDayOfWeek: this.engDaysShortName[dayOfWeek]
  };

  return dateObj;
}

// Function to convert BS date to AD date
bs2ad(date: any): any {
  return this.offsetADDays(this.countBSDays(date));
}

// Function to convert AD date to BS date
ad2bs(date: string): any {
  return this.offsetBSDays(this.countADDays(date));
}

// Function to add one day to a BS date
addOneDayToBSDate(bsDate: string): any {
  const dateArr = bsDate.split('/').map(str => Number(str));
  let bsYear = dateArr[0];
  let bsMonth = dateArr[1];
  let bsDay = dateArr[2];

  // Increment the day
  bsDay++;

  // Check if the day exceeds the number of days in the current month
  const totalDaysInCurrentMonth = this.calendarData[bsYear][bsMonth - 1];
  if (bsDay > totalDaysInCurrentMonth) {
    bsDay = 1; // Reset day to 1
    bsMonth++; // Move to the next month

    // Check if the month exceeds 12
    if (bsMonth > 12) {
      bsMonth = 1; // Reset month to January
      bsYear++; // Move to the next year
    }
  }

  // Prepare the BS date object
  const bsDateObj = {
    year: bsYear,
    month: bsMonth,
    day: bsDay,
    strMonth: this.ne.monthsName[bsMonth - 1],
    strShortMonth: this.ne.monthsShortName[bsMonth - 1],
    dayOfWeek: new Date(bsYear, bsMonth - 1, bsDay).getDay(), // Get the day of the week
    strDayOfWeek: this.getNepaliDayOfWeek(new Date(bsYear, bsMonth - 1, bsDay).getDay()).full,
    strShortDayOfWeek: this.getNepaliDayOfWeek(new Date(bsYear, bsMonth - 1, bsDay).getDay()).short,
    strMinDayOfWeek: this.getNepaliDayOfWeek(new Date(bsYear, bsMonth - 1, bsDay).getDay()).min
  };

  return {
    ne: {
      year: this.getNepaliNumber(bsDateObj.year),
      month: this.getNepaliNumber(bsDateObj.month),
      day: this.getNepaliNumber(bsDateObj.day),
      strMonth: bsDateObj.strMonth,
      strShortMonth: bsDateObj.strShortMonth,
      dayOfWeek: this.getNepaliNumber(bsDateObj.dayOfWeek),
      strDayOfWeek: bsDateObj.strDayOfWeek,
      strShortDayOfWeek: bsDateObj.strShortDayOfWeek,
      strMinDayOfWeek: bsDateObj.strMinDayOfWeek
    },
    en: {
      year: bsDateObj.year,
      month: bsDateObj.month,
      day: bsDateObj.day,
      strMonth: bsDateObj.strMonth,
      strShortMonth: bsDateObj.strShortMonth,
      dayOfWeek: bsDateObj.dayOfWeek,
      strDayOfWeek: bsDateObj.strDayOfWeek,
      strShortDayOfWeek: bsDateObj.strShortDayOfWeek,
      strMinDayOfWeek: bsDateObj.strMinDayOfWeek
    }
  };
}


}
